<template>
  <NCard :title="teamName" :bordered="false">
    <template #header-extra>
      <img :src="getTeamLogo()" />
    </template>
    <NSpace vertical>
      <NText strong>{{ teamConference }}</NText>
    </NSpace>
    <template #action>
      <NFlex>
        <NButton strong secondary>Stats</NButton>
        <NButton strong secondary>Roster</NButton>
        <NButton strong secondary @click="() => router.push(`/cfb/games/${props.team.school}`)">Games</NButton>
      </NFlex>
    </template>
  </NCard>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { NFlex, NSpace, NText, NCard, NButton } from 'naive-ui'; 

const router = useRouter();

const props = defineProps(['team']);

const teamName = computed(() => {
  return `${props.team.school} ${props.team.mascot}`;
});

const teamConference = computed(() => {
  return props.team.conference;
});

const getTeamLogo = () => {
  return props.team.logo_secondary;
};
</script>

<style scoped>
img {
  height: 40px;
}
.n-card {
  width: 32%;
}
@media (max-width: 600px) {
  .n-card {
    width: 100%;
  }
}
</style>