<template>
  <PlayerPage
    :sport="SPORT"
    :rate-stat-fn="getDefaultRateStat"
  />
</template>

<script setup>
import PlayerPage from '@/pages/shared/PlayerPage';

const SPORT = 'nfl';

const getDefaultRateStat = (position) => {
  let defaultRateStat = 'passCmp';
  switch (position) {
    case 'RB':
      defaultRateStat = 'rushCar';
      break;
    case 'WR':
    case 'TE':
      defaultRateStat = 'recRec';
      break;
  }
  return defaultRateStat;
};
</script>
