import { createRouter, createWebHashHistory } from 'vue-router';

import TeamsPageCFB from '@/pages/cfb/TeamsPageCFB';
import GamesPageCFB from '@/pages/cfb/GamesPageCFB';

import EventsPageNFL from '@/pages/nfl/EventsPageNFL';
import EventPlayerPropsPageNFL from '@/pages/nfl/EventPlayerPropsPageNFL';
import PlayerPageNFL from '@/pages/nfl/PlayerPageNFL';
import PlayersPageNFL from '@/pages/nfl/PlayersPageNFL';
import DepthChartsPageNFL from '@/pages/nfl/DepthChartsPageNFL';
import MatchupPageNFL from './pages/nfl/MatchupPageNFL';

import EventsPageMLB from '@/pages/mlb/EventsPageMLB';
import EventPlayerPropsPageMLB from '@/pages/mlb/EventPlayerPropsPageMLB';
import PlayerPageMLB from '@/pages/mlb/PlayerPageMLB';
import PlayersPageMLB from '@/pages/mlb/PlayersPageMLB';
import SchedulePageMLB from '@/pages/mlb/SchedulePageMLB';

import LinesPageNBA from './pages/nba/LinesPageNBA';
import PlayerPageNBA from '@/pages/nba/PlayerPageNBA';
import PlayersPageNBA from '@/pages/nba/PlayersPageNBA';
import LeagueChartsPageNBA from '@/pages/nba/LeagueChartsPageNBA';

const baseRoutes = [
  { path: '/', redirect: '/nfl/events' },
];

const nflRoutes = [
  { path: '/nfl', redirect: '/nfl/events' },
  { path: '/nfl/events', component: EventsPageNFL },
  { path: '/nfl/events/:id', component: EventPlayerPropsPageNFL },
  { path: '/nfl/players', component: PlayersPageNFL },
  { path: '/nfl/player/:id', component: PlayerPageNFL },
  { path: '/nfl/depth-charts', component: DepthChartsPageNFL },
  { path: '/nfl/matchup/:id', component: MatchupPageNFL },
];

const mlbRoutes = [
  { path: '/mlb', redirect: '/mlb/events' },
  { path: '/mlb/events', component: EventsPageMLB },
  { path: '/mlb/events/:id', component: EventPlayerPropsPageMLB },
  { path: '/mlb/players', component: PlayersPageMLB },
  { path: '/mlb/player/:id', component: PlayerPageMLB },
  { path: '/mlb/schedule', component: SchedulePageMLB },
];

const nbaRoutes = [
  { path: '/nba', redirect: '/nba/lines' },
  { path: '/nba/lines', component: LinesPageNBA },
  { path: '/nba/players', component: PlayersPageNBA },
  { path: '/nba/player/:id', component: PlayerPageNBA },
  { path: '/nba/league/charts', component: LeagueChartsPageNBA },
];

const cfbRoutes = [
  { path: '/cfb', redirect: '/cfb/teams' },
  { path: '/cfb/teams', component: TeamsPageCFB },
  { path: '/cfb/games/:id', component: GamesPageCFB },
];

const routes = [
  ...baseRoutes,
  ...mlbRoutes,
  ...nbaRoutes,
  ...cfbRoutes,
  ...nflRoutes,
];

export const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
