<template>
  <div class="page-container">
    <div class="lines-container">
      <NCard :bordered="false">
        <NFlex justify="space-between" align="center">
          <NText strong type="success">{{ lastPullTimestamp }}</NText>
          <NButton @click="() => loadEvents('yes')">Refresh</NButton>
        </NFlex>
      </NCard>
      <NDivider />
      <NFlex justify="space-between">
        <EventCard v-for="event in events?.data" :key="event.id" :game="event"></EventCard>
      </NFlex>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from 'vue';
import { NFlex, NCard, NText, NDivider, NButton } from 'naive-ui';
import { useEventLinesDataFetch } from '@/composables/EventLinesDataFetch';

import EventCard from '@/components/EventCard';

const props = defineProps({
  sport: {
    type: String,
    required: true,
    default() {
      return '';
    },
  },
  fetchEvents: {
    type: Function,
    required: true,
    async default() {
      return {};
    },
  },
});

onMounted(async () => await loadEvents());

const events = ref([]);

const lastPullTimestamp = computed(() => {
  const timestamp = events.value?.timestamp;
  return timestamp ? new Date(timestamp).toLocaleString() : '';
});

const loadEvents = async (refresh = 'no') => {
  const eventsData = await useEventLinesDataFetch(props.sport, refresh);
  events.value = eventsData;
};
</script>

<style>
@media print {
  .no-print {
    display: none;
  }
}
</style>