<template>
  <PlayersPage
    :sport="SPORT"
    :columns="TABLE_COLUMNS"
    :positions="PLAYER_POSITIONS"
    :name-formatter-fn="nameFormatter"
  />
</template>

<script setup>
import PlayersPage from '@/pages/shared/PlayersPage';

const SPORT = 'nfl';
const PLAYER_POSITIONS = ['QB', 'RB', 'FB', 'WR', 'TE'];
const TABLE_COLUMNS = [
  { title: 'Player', key: 'name' },
  { title: 'Team', key: 'teamId' },
  { title: 'Position', key: 'position' },
  { title: 'Age', key: 'age' },
  { title: 'School', key: 'school' },
];

const nameFormatter = (playerName) => {
  const parts = playerName.split('-');
  return parts.map((part) => {
    const formatted = part.charAt(0).toUpperCase() + part.slice(1);
    const formattedLowerCase = formatted.toLowerCase();
    if (['ii', 'iii'].includes(formattedLowerCase)) return formatted.toUpperCase();
    return formatted;
  }).join(' ');
};
</script>
