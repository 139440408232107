<template>
  <div class="page-container">
    <div class="cards-container">
      <div class="img-container">
        <div>
          <PlayerHeadshot :sport="props.sport" :player="player" />
          <NText>{{ formatName(player.name) }} | {{ formatTeam(player.teamId, teams) }}</NText>
        </div>
        <NText v-if="playerStatus === 'active'" type="success" class="status"><b>ACTIVE</b></NText>
        <NText v-else-if="playerStatus === 'gtd'" type="warning" class="status"><b>GTD</b></NText>
        <NText v-else-if="playerStatus === 'out'" type="error" class="status"><b>OUT</b></NText>
      </div>

      <NSelect
        placeholder="Season Year"
        v-model:value="seasonYear"
        :options="seasonYearSelectOptions"
        :on-update:value="handleChangeSeasonYear"
      />

      <DataTable
        class="averages-table"
        :data="averagesTableData"
        :columns="averagesTableColumns"
        :loading="averagesTableLoading"
      />

      <DataTable
        class="averages-table-transposed"
        :data="averagesTableDataTransposed"
        :columns="averagesTableColumnsTransposed"
        :loading="averagesTableLoadingTransposed"
      />

      <NSelect
        placeholder="Rate Table Stat"
        v-model:value="rateTableStat"
        :options="rateTableSelectOptions"
        :on-update:value="handleChangeRateTableStat"
      />

      <DataTable
        :data="rateTableData"
        :columns="rateTableColumns"
        :loading="rateTableLoading"
        :render-cell="renderFormattedCell"
      />

      <div class="chart" ref="chartContainer"></div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { h, ref, computed, onMounted, defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NSelect } from 'naive-ui';

import { scatterChart } from '@/charts/scatter-chart';
import { formatTeam, formatName } from '@/util/shared';
import { useSportDataFetch } from '@/composables/SportDataFetch';
import { usePlayerDataFetch } from '@/composables/PlayerDataFetch';
import DataTable from '@/components/DataTable';
import PlayerHeadshot from '@/components/PlayerHeadshot';

const props = defineProps(['sport', 'rateStatFn']);

onMounted(async () => {
  setSeasonYearSelect();

  await fetchSportData();
  await fetchPlayerData();

  setRateTableStat();
  setRateTable();
  setAveragesTable();
  renderChart({});
});

const route = useRoute();
const { id } = route.params;

const teams = ref([]);
const players = ref([]);

const seasonYear = ref(2024);
const seasonYearSelectOptions = ref([]);

const apiData = ref({});

const chartContainer = ref(null);

const rateTableStat = ref('');
const rateTableSelectOptions = ref([]);

const rateTableData = ref([]);
const rateTableColumns = ref([]);
const rateTableLoading = ref(true);

const averagesTableData = ref([]);
const averagesTableColumns = ref([]);
const averagesTableLoading = ref(true);

const averagesTableDataTransposed = ref([]);
const averagesTableColumnsTransposed = ref([]);
const averagesTableLoadingTransposed = ref(true);

const player = computed(() => {
  return players.value.find((player) => player.id == id) || {};
});

const playerStatus = computed(() => {
  return apiData?.value?.meta?.status?.toLowerCase();
});

const fetchSportData = async () => {
  const sportData = await useSportDataFetch(props.sport);
  teams.value = sportData.teams;
  players.value = sportData.players;
};

const fetchPlayerData = async () => {
  const playerData = await usePlayerDataFetch(
    player.value?.id,
    player.value?.name,
    seasonYear.value,
    props.sport
  );
  apiData.value = playerData;
};

const setRateTableStat = (stat) => {
  if (stat) {
    rateTableStat.value = stat;
  } else {
    rateTableStat.value = props.rateStatFn(player.value?.position.trim());
  }
};

const setRateTable = () => {
  if (!apiData.value?.rate_tables) {
    rateTableLoading.value = false;
    return;
  }

  const rateTable = apiData.value?.rate_tables.find((table) => {
    return table.key === rateTableStat.value;
  }); 

  rateTableSelectOptions.value = apiData.value?.rate_tables?.map((table) => {
    const scope = table.scope ? String(table.scope).toUpperCase() : undefined;
    const label = scope ? `${table.name} - ${scope}` : table.name;
    const value = table.key;
    return { label, value };
  });

  rateTableData.value = rateTable?.data;
  rateTableColumns.value = [
    { title: rateTable?.name, key: 'rate', align: 'center', className: 'headers' },
    { title: 'L10', key: 'L10HitPercent', align: 'center' },
    { title: 'L9', key: 'L9HitPercent', align: 'center' },
    { title: 'L8', key: 'L8HitPercent', align: 'center' },
    { title: 'L7', key: 'L7HitPercent', align: 'center' },
    { title: 'L6', key: 'L6HitPercent', align: 'center' },
    { title: 'L5', key: 'L5HitPercent', align: 'center' },
    { title: 'L4', key: 'L4HitPercent', align: 'center' },
    { title: 'L3', key: 'L3HitPercent', align: 'center' },
    { title: 'L2', key: 'L2HitPercent', align: 'center' },
    { title: 'L1', key: 'L1HitPercent', align: 'center' },
  ];
  rateTableLoading.value = false;
};

const setAveragesTable = () => {
  if (!apiData.value?.averages) {
    averagesTableLoading.value = false;
    return;
  }

  averagesTableData.value = apiData.value?.averages;

  averagesTableColumns.value = [
    { title: 'Stat', key: 'stat' },
    { title: 'Avg', key: 'Avg' },
    { title: 'L1Avg', key: 'L1Avg' },
    { title: 'L5Avg', key: 'L5Avg' },
  ];
  averagesTableLoading.value = false;

  setAveragesTableTransposed();
};

const setAveragesTableTransposed = () => {
  if (!apiData.value?.averages_transposed) {
    averagesTableLoadingTransposed.value = false;
    return;
  }

  averagesTableDataTransposed.value = apiData.value?.averages_transposed?.data;
  averagesTableColumnsTransposed.value = apiData.value?.averages_transposed?.columns;
  averagesTableLoadingTransposed.value = false;
};

const setSeasonYearSelect = () => {
  seasonYearSelectOptions.value = [
    { label: '2024', value: 2024 },
    { label: '2023', value: 2023 },
  ];
}

const handleChangeSeasonYear = async (e) => {
  seasonYear.value = e;
  await fetchPlayerData();

  setRateTableStat();
  setRateTable();
  setAveragesTable();
  renderChart({});
}

const handleChangeRateTableStat = (e) => {
  setRateTableStat(e);
  setRateTable();
  renderChart({});
};

const renderFormattedCell = (value, rowData, column) => {
  const plainValue = value.split('%')
  const parsedValue = parseFloat(plainValue)

  if (column.className === 'headers') {
    const rowValues = Object.values(rowData);
    rowValues.shift();
    if (rowValues.every((datum) => datum === '100%')) {
      return h(NText, { depth: 2, class: 'l100' }, { default: () => value });
    }
    return h(NText, { depth: 2 }, { default: () => value });
  }

  let className = '';

  if (parsedValue >= 100) {
    className = 'l10';
  } else if (parsedValue >= 90) {
    className = 'l9';
  } else if (parsedValue >= 80) {
    className = 'l8';
  } else if (parsedValue >= 70) {
    className = 'l7';
  } else if (parsedValue >= 60) {
    className = 'l6';
  }  else if (parsedValue === 50) {
    className = '';
  } else if (parsedValue >= 40) {
    className = 'l5';
  } else if (parsedValue >= 30) {
    className = 'l4';
  } else if (parsedValue >= 20) {
    className = 'l3';
  } else if (parsedValue >= 10) {
    className = 'l2';
  } else {
    className = 'l1';
  }

  return h(NText, { depth: 1, class: className }, { default: () => value });
};

const renderChart = async({ stats }) => {
  if (!apiData.value?.charts?.scatter || !rateTableStat.value) {
    return;
  }

  if (rateTableStat.value === 'PRA') {
    stats = ['PTS', 'REB', 'AST'];
  }

  if (rateTableStat.value === 'TRE') {
    stats = ['3PT'];
  }

  if (rateTableStat.value === 'TWOB') {
    stats = ['2B'];
  }

  if (rateTableStat.value === 'THREEB') {
    stats = ['3B'];
  }

  stats = stats ? stats : [rateTableStat.value];

  const data = [...apiData.value.charts.scatter].filter((group) => {
    return stats.includes(group.name);
  });

  if (!data.length) {
    return;
  }

  const groupValues = data.map((group) => group.values);
  const flattenedValues = _.flatten(groupValues);
  const max = _.maxBy(flattenedValues, (o) => o.value);
  const min = _.minBy(flattenedValues, (o) => o.value);

  const adjustedMax = max && max.value ? max.value + 2 : 0;
  const adjustedMin = min && min.value ? min.value - 5 < 0 ? 0 : min.value - 5 : 0;

  const svg = scatterChart(data, {
    xAccessor: 'key',
    yAccessor: 'value',
    yExtent: [adjustedMin, adjustedMax],
    stats,
  });

  if (chartContainer.value) {
    chartContainer.value.innerHTML = '';
  }

  chartContainer.value.appendChild(svg);
};
</script>

<style>
  div.tooltip {
    position: absolute;
    text-align: center;
    padding: .2rem;
    background: #313639;
    color: #f9f9f9;
    border: 0px;
    pointer-events: none;
  }
  .img-container {
    height: 100px;
    font-size: 18px;
    border-bottom: 2px solid #3f3f3f;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .chart {
    margin-top: 10px;
    border: 1px solid #3f3f3f;
    border-radius: 3px;
    background-color: #18181c;
  }
  .n-data-table .n-data-table-td:has(.n-text.l1) {
    background-color: #87899A;
  }
  .n-data-table .n-data-table-td:has(.n-text.l2) {
    background-color: #787A8E;
  }
  .n-data-table .n-data-table-td:has(.n-text.l3) {
    background-color: #696B81;
  }
  .n-data-table .n-data-table-td:has(.n-text.l4) {
    background-color: #5A5D75;
  }
  .n-data-table .n-data-table-td:has(.n-text.l5) {
    background-color: #4B4E68;
  }
  .n-data-table .n-data-table-td:has(.n-text.l6) {
    background-color: #3C3F5B;
  }
  .n-data-table .n-data-table-td:has(.n-text.l7) {
    background-color: #2D304F;
  }
  .n-data-table .n-data-table-td:has(.n-text.l8) {
    background-color: #1E2242;
  }
  .n-data-table .n-data-table-td:has(.n-text.l9) {
    background-color: #0F1336;
  }
  .n-data-table .n-data-table-td:has(.n-text.l10) {
    background-color: #000429;
  }
  .button {
    position: absolute;
    top: 0;
  }
  .averages-table {
    display: none;
  }
  .averages-table-transposed {
    display: block;
  }
  .n-select {
    width: 25%;
  }
  @media print {
    .no-print {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .player-info {
      display: none;
    }
    .averages-table {
      display: block;
    }
    .averages-table-transposed {
      display: none;
    }
    .n-select {
      width: 100%;
    }
  } 
</style>
