<template>
  <img :src="teamLogo()" class="logo" />
</template>

<script setup>
import { useRoute } from 'vue-router';
import { defineProps } from 'vue';

const props = defineProps(['teamId']);

const route = useRoute();

const teamLogo = () => {
  const sport = route.fullPath.split('/')[1];
  if (sport && props.teamId) {
    return `${sport}-logos/${props?.teamId}.png`;
  }
  return '';
};
</script>

<style scoped>
img {
  height: 20px;
}
</style>