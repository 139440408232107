<template>
  <div class="page-container">
    <div class="lines-container">
      <div class="no-print space-bottom">
        <NCard :bordered="false">
          <NFlex justify="space-between" align="center">
            <NText>Player Props</NText>
            <NText strong type="error">{{ message }}</NText>
            <NButton strong tertiary @click="(e) => loadReport(e, 'yes')">Refresh</NButton>
          </NFlex>
          <NSpace vertical>
            <NText type="success">{{ lastPullTimestamp }}</NText>
          </NSpace>
        </NCard>
      </div>
      <NTabs type="segment" animated>
        <NTabPane name="overs" tab="Overs">
          <DataTable
            :data="tableDataLineReportOvers"
            :columns="tableColumnsLineReport"
            :loading="tableLoadingLineReport"
            :render-cell="renderFormattedCellOvers"
          />
        </NTabPane>
        <NTabPane name="unders" tab="Unders">
          <DataTable
            :data="tableDataLineReportUnders"
            :columns="tableColumnsLineReport"
            :loading="tableLoadingLineReport"
            :render-cell="renderFormattedCellUnders"
          />
        </NTabPane>
      </NTabs>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { h, ref, onMounted } from 'vue';
import { NFlex, NCard, NText, NSpace, NButton, NTabPane, NTabs } from 'naive-ui';
import { usePlayerPropsDataFetch } from '@/composables/PlayerPropsDataFetch';

import { SPORT } from '@/util/util_nfl';
import { cellFormatters, cellClassesOvers, cellClassesUnders } from '@/util/shared';

import DataTable from '@/components/DataTable';

const route = useRoute();

onMounted(async () => loadReport());

const message = ref('');

const tableLoadingLineReport = ref(false);
const tableColumnsLineReport = ref([]);
const tableDataLineReportOvers = ref([]);
const tableDataLineReportUnders = ref([]);

const lastPullTimestamp = ref('');

const loadReport = async (_, refresh = 'no') => {
  const { id } = route.params;

  tableLoadingLineReport.value = true;
  tableColumnsLineReport.value = [
    { title: 'Rank', key: 'rank', align: 'center' },
    { title: 'Name', key: 'player' },
    { title: 'Prop', key: 'prop' },
    { title: 'Line', key: 'line', align: 'center' },
    { title: 'Odds', key: 'price', align: 'center' },
    { title: 'Average', key: 'averageLast5', align: 'center' },
    { title: 'HR Last 5', key: 'hitRateLast5', align: 'center' },
    { title: 'Opp Rank', key: 'opponentRankVsPosition', align: 'center' },
    { title: 'Status', key: 'playerStatus', align: 'center' },
  ];

  const report = await usePlayerPropsDataFetch(SPORT, id, refresh);

  if (report.message) {
    message.value = report.message;
  }

  tableDataLineReportOvers.value = report.overs;
  tableDataLineReportUnders.value = report.unders;
  tableLoadingLineReport.value = false;
};

const renderFormattedCellOvers = (value, rowData, column) => {
  const { key } = column;
  const formatterFn = cellFormatters[key];
  const classNameFn = cellClassesOvers[key];
  const options = { depth: 1 };

  let cellValue = value;

  if (formatterFn) {
    cellValue = formatterFn(value);
  }

  if (classNameFn) {
    const className = classNameFn(value, rowData, column);
    options.class = className;
  }

  return h(NText, options, { default: () => cellValue });
};

const renderFormattedCellUnders = (value, rowData, column) => {
  const { key } = column;
  const formatterFn = cellFormatters[key];
  const classNameFn = cellClassesUnders[key];
  const options = { depth: 1 };

  let cellValue = value;

  if (formatterFn) {
    cellValue = formatterFn(value);
  }

  if (classNameFn) {
    const className = classNameFn(value, rowData, column);
    options.class = className;
  }

  return h(NText, options, { default: () => cellValue });
};
</script>

<style>
.space-top {
  margin-top: 20px;
}

.space-bottom {
  margin-bottom: 20px;
}

.n-text.good {
  color: #1dff26;
}

.n-data-table
.n-data-table-td:has(.n-text.good) {
  background-color: #1b3b1c;
}

.n-text.medium {
  color: #FFC107;
}

.n-data-table
.n-data-table-td:has(.n-text.medium) {
  background-color: #643c00;
}

.n-text.bad {
  color: #ff1100;
}

.n-data-table
.n-data-table-td:has(.n-text.bad) {
  background-color: #331210;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>