<template>
  <div class="page-container">
    <div class="cards-container">
      <NSelect
        v-if="props.showConferenceSelect"
        v-model:value="selectedConference"
        :options="conferenceOptions"
        :on-update:value="updateConference"
      />
      <NFlex justify="space-between">
        <TeamCard v-for="team of teamsShown" :key="team.id" :team="team" />
      </NFlex>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { ref, computed, onMounted, defineProps } from 'vue';
import { NFlex, NSelect } from 'naive-ui';
import { useSportDataFetch } from '@/composables/SportDataFetch';

import TeamCard from '@/components/TeamCard';

onMounted(async () => {
  await fetchSportData();
});

const props = defineProps(
  [
    'sport',
    'showConferenceSelect',
  ]
);

const teams = ref([]);
const teamsShown = computed(() => {
  if (props.showConferenceSelect) {
    return teams.value.filter((t) => {
      return t.conference === selectedConference.value;
    });
  }
  return teams.value;
});

const selectedConference = ref('Big Ten');
const conferenceOptions = ref([]);
const updateConference = (e) => {
  selectedConference.value = e;
};

const fetchSportData = async () => {
  const sportData = await useSportDataFetch(props.sport);

  if (sportData?.teams?.length) {
    const conferences = _.uniq(
      sportData.teams.map((t) => t.conference)
    );

    teams.value = sportData.teams;
    conferenceOptions.value = conferences.map((c) => {
      return {
        value: c,
        label: c,
      };
    });
  }
};
</script>