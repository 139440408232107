<template>
  <div class="page-container">
    <div class="lines-container">
      <NCard>
        <NSpace vertical>
          <NFlex strong v-if="homeTeam && awayTeam">
            <TeamLogo :teamId="awayTeam.id" />
            <NText strong>{{ awayTeam.prettyName }}</NText>
            <NText>{{ awayTeam.record }}</NText>
          </NFlex>
          <NFlex>
            <TeamLogo :teamId="homeTeam.id" />
            <NText strong>{{ homeTeam.prettyName }}</NText>
            <NText>{{ homeTeam.record }}</NText>
          </NFlex>
          <NText v-if="overUnder">Predicted Over-Under: {{ overUnder }}</NText>
        </NSpace>
      </NCard>
      <NDivider />
      <NFlex justify="space-between" align="center">
        <NCard>
          <div ref="chartContainerTeamScore"></div>
          <template #action><NText strong>Team Score</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerOpponentScore"></div>
          <template #action><NText strong>Opponent Score</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerFirstDowns"></div>
          <template #action><NText strong>1st Downs</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerTotalYards"></div>
          <template #action><NText strong>Total Yards</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerPassYards"></div>
          <template #action><NText strong>Pass Yards</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerRushYards"></div>
          <template #action><NText strong>Rush Yards</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerOffenseTurnovers"></div>
          <template #action><NText strong>Turnovers (Offense)</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerFirstDownsAllowed"></div>
          <template #action><NText strong>1st Downs Allowed</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerTotalYardsAllowed"></div>
          <template #action><NText strong>Total Yards Allowed</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerTotalPassYardsAllowed"></div>
          <template #action><NText strong>Total Pass Yards Allowed</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerTotalRushYardsAllowed"></div>
          <template #action><NText strong>Total Rush Yards Allowed</NText></template>
        </NCard>
        <NCard>
          <div ref="chartContainerDefenseTurnovers"></div>
          <template #action><NText strong>Turnovers (Defense)</NText></template>
        </NCard>
      </NFlex>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NFlex, NCard, NSpace, NDivider } from 'naive-ui';

import { barPlotHorizontal } from '@/charts/BarPlotHorizonal';
import { useMatchupFetch } from '@/composables/MatchupDataFetch';

import TeamLogo from '@/components/TeamLogo';

const SPORT = 'nfl';
const route = useRoute();

const awayTeam = ref({});
const homeTeam = ref({});

const overUnder = ref(0);

const chartContainerTeamScore = ref(null);
const chartContainerOpponentScore = ref(null);
const chartContainerFirstDowns = ref(null);
const chartContainerTotalYards = ref(null);
const chartContainerPassYards = ref(null);
const chartContainerRushYards = ref(null);
const chartContainerOffenseTurnovers = ref(null);
const chartContainerFirstDownsAllowed = ref(null);
const chartContainerTotalYardsAllowed = ref(null);
const chartContainerTotalPassYardsAllowed = ref(null);
const chartContainerTotalRushYardsAllowed = ref(null);
const chartContainerDefenseTurnovers = ref(null);

onMounted(async () => await fetchMatchup());

const fetchMatchup = async () => {
  const id = route.params.id;
  
  if (!id) return;

  const teamIds = id.split('-');
  const matchupData = await useMatchupFetch(teamIds[0], teamIds[1], SPORT);

  const teams = matchupData.teams;
  const keyMetrics = matchupData.keyMetrics;
  const chartData = matchupData.chartData;

  awayTeam.value = teams.away;
  homeTeam.value = teams.home;
  overUnder.value = keyMetrics.predictedOU;

  renderChart(chartContainerTeamScore, chartData.teamScore);
  renderChart(chartContainerOpponentScore, chartData.opponentScore);
  renderChart(chartContainerFirstDowns, chartData.firstDowns);
  renderChart(chartContainerTotalYards, chartData.totalYards);
  renderChart(chartContainerPassYards, chartData.passYards);
  renderChart(chartContainerRushYards, chartData.rushYards);
  renderChart(chartContainerOffenseTurnovers, chartData.offenseTurnovers);
  renderChart(chartContainerFirstDownsAllowed, chartData.firstDownsAllowed);
  renderChart(chartContainerTotalYardsAllowed, chartData.totalYardsAllowed);
  renderChart(chartContainerTotalPassYardsAllowed, chartData.totalPassYardsAllowed);
  renderChart(chartContainerTotalRushYardsAllowed, chartData.totalRushYardsAllowed);
  renderChart(chartContainerDefenseTurnovers, chartData.defenseTurnovers);

};

const renderChart = (reference, data) => {
  const svg = barPlotHorizontal(data);

  if (reference.value) {
    reference.value.innerHTML = '';
  }

  reference.value.appendChild(svg);
};
</script>

<style scoped>
.n-card {
  width: 48%;
}
@media (max-width: 600px) {
  .n-card {
    width: 100%;
  }
}
</style>