<template>
  <NCard :title="startTime" :bordered="false">
    <NSpace vertical>
      <NSpace>
        <TeamLogo :teamId="getTeamId(awayTeam)"/>
        <NText>{{ awayTeam }}</NText>
        <NText :style="getPriceColor(awayTeamPrice)">{{ awayTeamPrice }}</NText>
      </NSpace>
      <NSpace>
        <TeamLogo :teamId="getTeamId(homeTeam)"/>
        <NText>{{ homeTeam }}</NText>
        <NText :style="getPriceColor(homeTeamPrice)">{{ homeTeamPrice }}</NText>
      </NSpace>
    </NSpace>
    <template #action>
      <NFlex>
        <RouterLink :to="getPlayerPropsLink()">
          <NButton strong tertiary size="small">Player Props Report</NButton>
        </RouterLink>
        <RouterLink :to="getMatchupReportLink()">
          <NButton strong tertiary size="small">Matchup Report</NButton>
        </RouterLink>
      </NFlex>
    </template>
  </NCard>
</template>

<script setup>
import { RouterLink } from 'vue-router';
import { ref, computed, defineProps, onMounted } from 'vue';
import { NFlex, NCard, NText, NSpace, NButton } from 'naive-ui';
import { SPORT, BOOK_MAKERS, AVAILABLE_MARKETS } from '@/util/util_nfl';
import { useSportDataFetch } from '@/composables/SportDataFetch';

import TeamLogo from '@/components/TeamLogo';

onMounted(async () => {
  const sportData = await useSportDataFetch(SPORT);
  teams.value = sportData.teams;
});

const props = defineProps({
  game: {
    type: Object,
    default() {
      return {};
    },
  },
});

const teams = ref([]);

const eventId = computed(() => props?.game.id);
const startTime = computed(() => formatEventTime(props?.game?.commence_time));
const awayTeam = computed(() => props?.game.away_team);
const homeTeam = computed(() => props?.game.home_team);
const awayTeamPrice = computed(() => getPrice(awayTeam.value));
const homeTeamPrice = computed(() => getPrice(homeTeam.value));

const getTeamId = (team) => {
  return teams.value.find((t) => t.prettyName === team)?.id;
};

const formatEventTime = (commence_time) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  const day = new Date(commence_time).getDay();
  const date = new Date(commence_time).toLocaleDateString();
  const time = new Date(commence_time).toLocaleTimeString();
  return [days[day], getDate(date), getTime(time)].join(' ');
};

const getDate = (date) => {
  return date.split('/').slice(0, 2).join('/');
};

const getTime = (time) => {
  const a = time.split(':').slice(0, 2).join(':');
  const b = time.split(':')[2].split(' ')[1];
  return [a, b].join(' ');
};

const getPrice = (key) => {
  let outcome;
  let price;
  try {
    const bookmaker = props?.game?.bookmakers.find((b) => b.key === BOOK_MAKERS);
    const market = bookmaker.markets.find((m) => m.key === AVAILABLE_MARKETS.h2h);
    outcome = market.outcomes.find((o) => o.name === key);
    price = outcome.price > 0 ? `+${outcome.price}` : outcome.price;
  } catch (err) {
    price = null;
  }
  return price;
};

const getPriceColor = (price) => {
  return price > 0 ? 'color: #e88080;' : 'color: #63e2b7;';
};

const getPlayerPropsLink = () => {
  return `/nfl/events/${eventId.value}`;
};

const getMatchupReportLink = () => {
  const homeTeamObj = teams.value.find((team) => team.prettyName === homeTeam.value);
  const awayTeamObj = teams.value.find((team) => team.prettyName === awayTeam.value);

  const homeTeamId = homeTeamObj?.proFootballReferenceId;
  const awayTeamId = awayTeamObj?.proFootballReferenceId;

  return homeTeamId && awayTeamId ? `/nfl/matchup/${awayTeamId}-${homeTeamId}` : '';
};
</script>

<style scoped>
.n-card {
  width: 32%;
}
@media (max-width: 600px) {
  .n-card {
    width: 100%;
  }
}
</style>