export async function useEventLinesDataPost(sport, data) {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;
  const url = `${endpoint}/api/${sport}/events`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.status;
}