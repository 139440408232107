<template>
  <NFlex justify="center" align="center">
    <NButton
      strong
      tertiary
      size="small"
      v-for="link in links" :key="link.value"
      @click="() => router.push(link.value)"
    >{{ link.label }}</NButton>
  </NFlex>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { NFlex, NButton } from 'naive-ui';

const router = useRouter();
const route = useRoute();

const links = computed(() => {
  const sport = route?.fullPath?.split('/')[1];

  if (!sport) {
    return [];
  }

  const nflOptions = [
    { label: 'Events', value: '/nfl/events', sport: 'nfl' },
    { label: 'Players', value: '/nfl/players', sport: 'nfl' },
    { label: 'Depth Charts', value: '/nfl/depth-charts', sport: 'nfl' },
  ];

  const nbaOptions = [
    { label: 'Lines', value: '/nba/lines', sport: 'nba' },
    { label: 'Players', value: '/nba/players', sport: 'nba' },
  ];

  const mlbOptions = [
    { label: 'Events', value: '/mlb/events', sport: 'mlb' },
    { label: 'Players', value: '/mlb/players', sport: 'mlb' },
    { label: 'Schedule', value: '/mlb/schedule', sport: 'mlb' },
  ];

  const cfbRoutes = [
    { label: 'Teams', value: '/cfb/teams', sport: 'cfb' },
  ];

  const options = [
    ...nflOptions,
    ...nbaOptions,
    ...mlbOptions,
    ...cfbRoutes,
  ];

  return options.filter((o) => o.sport === sport);
});
</script>

<style scoped>
.n-select {
  width: 150px;
}
</style>

