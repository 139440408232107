/* eslint-disable */

import * as d3 from 'd3';

export const barPlotHorizontal = (data) => {
  const margin = { top: 0, right: 10, bottom: 30, left: 100 };
  const width = 380 - margin.left - margin.right;
  const height = 200 - margin.top - margin.bottom;

  const max = d3.max(data, d => d.value);

  d3.select('svg').exit().remove();

  const adjustedWidth = width + margin.left + margin.right;
  const adjustedHeight = height + margin.top + margin.bottom;

  const svg = d3.create('svg')
    .attr('width', adjustedWidth)
    .attr('height', adjustedHeight)
    .attr('viewBox', [0, 0, width, adjustedHeight])

  // X AXISs
  const x = d3.scaleLinear().domain([0, max]).range([0, width]);

  svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
      .attr('transform', `translate(-10,0)rotate(-45)`)
      .style('text-anchor', 'end');

  // Y AXIS
  const y = d3.scaleBand()
    .range([0, height])
    .domain(data.map(d => d.team))
    .padding(.2);

  svg.append('g').attr('fill', 'white').call(d3.axisLeft(y));

  // BARS
  svg.selectAll('myRect')
    .data(data)
    .enter()
    .append('rect')
    .attr('y', (d) => y(d.team))
    .attr('height', (d) => y.bandwidth())
    .attr('fill', (d) => d.color)
    // NO BARS AT BEGINNING
    .attr('width', (d) => x(0))
    .attr('x', (d) => x(0));

  // Animation
  svg.selectAll('rect')
    .transition()
    .duration(800)
    .attr('x', (d) => x(0) + 1)
    .attr('width', (d) => x(d.value) + 1)
    .delay((d, i) => i * 100);

  svg.selectAll('.bar-label')
    .data(data)
    .enter()
    .append('text')
    .attr('class', 'bar-label')
    .attr('fill', 'white')
    .attr('x', (d) => x(d.value) + 4)
    .attr('y', (d) => y(d.team) + y.bandwidth() / 2)
    .text((d) => d.value);


  return svg.node();
}